import React from 'react'
import styles from './FormContainers.module.css'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

type Props = { className?: string; children?: React.ReactNode }

const InputContainer = ({ className, children, ...props }: Props) => (
  <div className={cx(styles.inputContainer, className)} {...props}>
    {children}
  </div>
)

const InputGroup = ({ className, children, ...props }: Props) => (
  <div className={cx(styles.inputGroup, className)} {...props}>
    {children}
  </div>
)

export { InputContainer, InputGroup }
