import * as Yup from 'yup'

const _email = Yup.string().email('Must be a valid email')

const _password = Yup.string().required('Required')

const _passwordNew = Yup.string().min(8, 'Must be at least 8 characters').required('Required')

const _passwordNewConfirm = Yup.string()
  .oneOf([Yup.ref('password'), undefined], 'Does not match password')
  .min(8, 'Must be at least 8 characters')
  .required('Required')

const addressSchema = Yup.object().shape({
  nickname: Yup.string().required('Required'),
  locationType: Yup.string(),
  name: Yup.string().required('Required'),
  street1: Yup.string().required('Required'),
  street2: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  country: Yup.string(),
  postcode: Yup.string(),
  phone: Yup.string(),
  phone2: Yup.string(),
  fax: Yup.string(),
  email: _email,
  contact: Yup.string()
})

const pricingRequestAddressSchema = Yup.object().shape({
  nickname: Yup.string().required('Required'),
  locationType: Yup.string(),
  name: Yup.string().required('Required'),
  street1: Yup.string().required('Required'),
  street2: Yup.string(),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
  postcode: Yup.string().required('Required'),
  phone: Yup.string(),
  phone2: Yup.string(),
  fax: Yup.string(),
  email: _email,
  contact: Yup.string()
})

const buyerAccountSchema = Yup.object().shape({
  displayName: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  taxNumber: Yup.string().required('Required'),
  departments: Yup.array().min(3, 'Choose at least 3 departments').max(6, 'Max of 6 departments')
})

const emailSchema = Yup.object().shape({
  email: _email.required('Required')
})

const loginSchema = Yup.object().shape({
  email: _email.required('Required'),
  password: _password
})

const orderOptionsSchema = Yup.object().shape({
  billingAddressId: Yup.string().required('Required'),
  shippingAddressId: Yup.string().required('Required')
})

const passwordSchema = Yup.object().shape({
  password: _passwordNew,
  passwordConfirmation: _passwordNewConfirm
})

const profileSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: _email.required('Required')
})

const updatedTermsSchema = Yup.object().shape({
  termsAccepted: Yup.boolean()
    .required('Required')
    .oneOf([true], 'You must read and agree to the updated Terms of Service to continue using Fieldfolio.')
})

const signupSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: _email.required('Required'),
  phone: Yup.string().required('Required'),
  password: _passwordNew,
  termsAccepted: Yup.boolean()
    .required('Required')
    .oneOf([true], 'You must accept the Terms of Service and Privacy Policy to sign up to Fieldfolio.')
})

const tradeReferenceSchema = Yup.object({
  businessName: Yup.string().required('Required'),
  contactName: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  email: _email.required('Required')
})

export {
  addressSchema,
  pricingRequestAddressSchema,
  buyerAccountSchema,
  emailSchema,
  loginSchema,
  orderOptionsSchema,
  passwordSchema,
  profileSchema,
  updatedTermsSchema,
  signupSchema,
  tradeReferenceSchema
}
