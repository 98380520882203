import React from 'react'
import Logo from 'components/Logo'
import ProgressBar, { ProgressBarStyle } from 'components/ProgressBar'
import VerticalFormContainer from '../VerticalFormContainer'
import classnames from 'classnames'
import styles from './AuthContainer.module.css'

const AuthContainer = ({
  children,
  heading,
  headingId = '',
  subhead,
  caption,
  progressPercent,
  centered,
  className,
  fullWidth = false,
  showLogo = false,
  verticalFormClassName
}: {
  children?: React.ReactNode
  heading?: string | React.ReactNode
  headingId?: string
  subhead?: string | React.ReactNode
  caption?: string | React.ReactNode
  progressPercent?: number
  centered?: boolean
  className?: string
  fullWidth?: boolean
  showLogo?: boolean
  verticalFormClassName?: string
}) => (
  <div
    className={classnames(
      styles.container,
      {
        [styles.centered]: centered,
        [styles.hasSubhead]: subhead,
        [styles.hasCaption]: caption,
        [styles.hasProgress]: progressPercent
      },
      className
    )}>
    <VerticalFormContainer alignLeft className={verticalFormClassName}>
      <div className={styles.header}>
        {showLogo && (
          <div className={styles.logoContainer}>
            <Logo />
          </div>
        )}
        {heading && (
          <h1 id={headingId} className={styles.heading}>
            {heading}
          </h1>
        )}
        {subhead && <p className={styles.subhead}>{subhead}</p>}
        {caption && <p className={styles.caption}>{caption}</p>}
      </div>
      {progressPercent && (
        <ProgressBar className={styles.progressBar} percent={progressPercent} style={ProgressBarStyle.THIN} />
      )}
      {!fullWidth && <div className={styles.body}>{children}</div>}
    </VerticalFormContainer>
    {fullWidth && <div className={styles.fullWidth}>{children}</div>}
  </div>
)

export default AuthContainer
