import React from 'react'
import { Formik, Form, Field } from 'formik'
import Link from 'next/link'
import { loginSchema } from 'lib/validation-schema'

import joinErrorPaths from 'lib/error-handling/join-error-paths'
import { useUserSession, CreateSessionResult } from 'hooks/useUserSession'
import styles from './LoginForm.module.css'

import Button from 'components/Button'
import { FormInput } from 'components/FormControls'
import { InputGroup } from 'components/FormContainers'
import { CreateUserSessionMutationInput } from '../../../../../__generated__/globalTypes'

export type LoginFormProps = {
  onLoginSuccess(result: CreateSessionResult): Promise<void>
}

const LoginForm = ({ onLoginSuccess }: LoginFormProps) => {
  const { createSession } = useUserSession()

  return (
    <>
      <Formik
        initialValues={
          {
            email: '',
            password: ''
          } as CreateUserSessionMutationInput
        }
        validateOnBlur={false}
        validationSchema={loginSchema}
        render={({ isSubmitting }) => (
          <Form data-ref="login-form">
            <InputGroup>
              <Field autoComplete="username" type="email" placeholder="Email" name="email" component={FormInput} />
              <Field
                autoComplete="password"
                type="password"
                placeholder="Password"
                name="password"
                component={FormInput}
              />
            </InputGroup>
            <Button isBlock kind="primary" type="submit" disabled={isSubmitting} isSubmitting={isSubmitting}>
              Login
            </Button>
          </Form>
        )}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          // To mimic Formik v1 behavior with regard to the isSubmitting value,
          // providing isSubmit with an async function wrapped in IIFE is necessary.
          // See described in https://github.com/formium/formik/issues/1957
          ;(async () => {
            let errors
            setSubmitting(true)

            const result = await createSession({ variables: { input: values } })
            const { data } = result

            if (data?.createUserSession.success) {
              await onLoginSuccess(result)
            } else {
              errors = joinErrorPaths(data?.createUserSession?.errors)

              if (errors && errors?.length > 0) {
                errors?.forEach(e => (e.path && e.message ? setFieldError(e.path, e.message) : ''))
              }
            }

            setSubmitting(false)
          })()
        }}
      />
      <div className={styles.fineprint}>
        <small>
          <Link href="/reset-password">
            <a className={styles.mutedLink}>Forgot your password?</a>
          </Link>
        </small>
      </div>
    </>
  )
}

export default LoginForm
