import React, { useEffect } from 'react'
import Link from 'next/link'
import Head from 'next/head'

import useIntercomBoot from 'components/Intercom/useIntercomBoot'
import { useUserSession } from 'hooks'

import AuthContainer from 'modules/auth/components/AuthContainer'
import BasePage from 'modules/marketplace/layout/components/BasePage'
import LoginForm from 'modules/auth/components/LoginForm'

const LoginPage = () => {
  const { isGuest, handleNewSession, sessionLoading } = useUserSession()
  const { bootIntercom } = useIntercomBoot()

  useEffect(() => {
    if (!sessionLoading && !isGuest) {
      handleNewSession()
      bootIntercom()
    }
  }, [handleNewSession, isGuest, bootIntercom, sessionLoading])

  return (
    <BasePage>
      <Head>
        <title>Login · Fieldfolio</title>
        <link rel="canonical" href={`${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/login`} />
      </Head>
      <AuthContainer heading="Welcome Back!" subhead="Login to Fieldfolio">
        <LoginForm
          onLoginSuccess={async result => {
            handleNewSession(result?.data?.createUserSession)
          }}
        />
        <section>
          <small>
            New to Fieldfolio?{' '}
            <Link href="/signup">
              <a>Sign up</a>
            </Link>
          </small>
        </section>
      </AuthContainer>
    </BasePage>
  )
}

export default LoginPage
