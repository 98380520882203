import classNames from 'classnames/bind'

import styles from './VerticalFormContainer.module.css'
const cx = classNames.bind(styles)

type VerticalFormContainerProps = {
  alignLeft?: boolean
  alignRight?: boolean
  className?: string
  children?: React.ReactNode
}

const VerticalFormContainer = ({
  alignLeft,
  alignRight,
  className,
  children,
  ...props
}: VerticalFormContainerProps) => (
  <div className={cx(styles.verticalFormContainer, className, { alignLeft, alignRight })} {...props}>
    {children}
  </div>
)

export default VerticalFormContainer
