import React from 'react'
import classNames from 'classnames'

import styles from './ProgressBar.module.css'

export enum ProgressBarStyle {
  REGULAR = 'regular',
  THIN = 'thin'
}

type Props = {
  className?: string
  progressBarClassName?: string
  percent: number
  style?: ProgressBarStyle
}

const ProgressBar = ({ className, progressBarClassName, percent, style = ProgressBarStyle.REGULAR }: Props) => (
  <div className={classNames(styles.track, className, styles[style])}>
    <div className={classNames(styles.bar, progressBarClassName)} style={{ width: `${percent}%` }} />
  </div>
)

export default ProgressBar
